window.onload = function() {
	// Ensure the email field exists in the DOM
	var emailField = document.getElementById('tfa_2');
	if (!emailField) {
		console.error("Email field not found in the DOM.");
		return;
	}

	// Attach blur event listener to the email field to dynamically enable/disable the submit button
	emailField.addEventListener('blur', function() {
		updateSubmitButton();
	});

	// Attach event listener to a parent element (in this case, the document body)
	document.body.addEventListener('submit', function(event) {
		// Check if the submitted form is the one you're interested in
		if (event.target && event.target.id === '217787') {
			// Prevent the form from submitting if the email is invalid
			if (!validateForm()) {
				event.preventDefault();
			}
		}
	});

	function validateForm() {
		var emailField = document.getElementById('tfa_2');
		var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			if (!emailField.value.match(emailRegex)) {
			alert('Please enter a valid email address.');
			return false; // prevent form submission
		}
		return true; // allow form submission
	}

	function updateSubmitButton() {
		var submitButton = document.getElementById('submit_button');

		// Enable the submit button if the email is valid, otherwise disable it
		submitButton.disabled = !validateForm();
	}
};